.App {
  text-align: center;
  font-family: Calibri;
}
.App-name{
  /* float:right; */margin:20px;text-align: center; display: block;
}
.App-name span {color:white;text-transform:uppercase; text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);}
.App-name span + span {color: rgb(189,215,238);text-transform:capitalize;}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-stage {
  
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  background-image: url('/assets/images/banner-admin-blur.jpg');
  background-repeat: no-repeat;
  background-size: cover;
 
}

.App-stage.ready {
  
  
  background-image: url('/assets/images/banner-admin.jpg');
  background-repeat: no-repeat;
  background-size: cover;
 
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.busy-icon {
  animation: rotation 1s infinite ease-in-out;
  display: inline-block;
  margin:0px 10px;
}

@keyframes rotation {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(359deg);
  }
}



.App-dialog{
  transition: all 0.5s ease;
  /* background-color: rgba(73, 73, 73, 0.7); */
  background-color: rgba(255, 255, 255, 0.7);
  min-height: 10vh;
  min-width: 33vw;
  text-align: left;
  /* color:#fff; */
  color:#333;
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7); */
  text-shadow: 1px 1px 1px rgba(237, 237, 237, 0.7);
  border-radius:3px;
  
}

.App-dialog > div {
  margin:20px 20px;
  transition: all 0.5s ease;
}


.App-dialog .mini {font-size:small; line-height: unset;margin-bottom: 0;}
.App-dialog  h1 .mini {font-size:medium}
.App-dialog form {margin:10px 30px}
.App-dialog form label {font-size:smaller}
.App-dialog .form-group {
  margin-bottom: 0.7rem;
}

.App-dialog .btn , .App-dialog .form-control-flat{
border-radius: 0rem;
}

.App-dialog .login-button-row  {margin-left:0px;margin-right:0px;}

.App-dialog  table {background-color: rgba(255,255,255,0.4); font-size:medium ;color:#000}

.fz-400 {font-size: 1rem !important;}